<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="계획 및 담당자의견" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
            <c-btn
              v-if="!disabled"
              :url="updateUrl"
              :isSubmit="isSave"
              :param="accidentInfo"
              mappingType="PUT"
              label="계획/의견저장"
              icon="save"
              @beforeAction="saveAccident('SAVE')"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-9 col-sm-9 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="6"
              label="단기계획"
              name="shortPlan"
              v-model="accidentInfo.shortPlan">
            </c-textarea>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="6"
              label="중장기 계획"
              name="longPlan"
              v-model="accidentInfo.longPlan">
            </c-textarea>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="6"
              label="담당자 의견"
              name="managerOpinion"
              v-model="accidentInfo.managerOpinion">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridImpr"
        title="부적합 개선 목록"
        :columns="gridImpr.columns"
        :data="accidentInfo.imprList"
        gridHeight="300px"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled && popupParam.iimAccidentId" label="개선요청" :showLoading="false"  icon="add" @btnClicked="addImpr" />
            <c-btn v-if="editable && !disabled && popupParam.iimAccidentId" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'near-cause-prevention',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
    tabKey: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      editable: true,
      standardInfo: {
        accidentNo: '',
        accidentName: '',
        accidentKindName: '',
        accidentGradeName: '',
        occurrenceDate: '',
        occurrenceDeptName: '',
        occurrenceModeLargeName: '',
        occurrenceModeSmallName: '',
        accidentStatusCd: '',
      },
      accidentInfo: {
        shortPlan: '',
        longPlan: '',
        managerOpinion: '',
        accidentStautsCd: '',
        imprList: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
        ],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      isSave2: false,
      updateUrl: 'transactionConfig.sop.iim.accident.near.prevention.update.url',
      updateCauseUrl: 'transactionConfig.sop.iim.accident.near.prevention.update.cause.url',
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.accidentInfo.accidentStatusCd) && this.accidentInfo.accidentStatusCd !== 'ISNC000001'
    }
  },
  watch: {
    'tabKey'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.iim.accident.near.get.url;
      this.getInfoUrl = selectConfig.sop.iim.accident.near.prevention.get.url;
      this.updateUrl = transactionConfig.sop.iim.accident.near.prevention.update.url

      this.getStdInfo();
      this.getDetail();
    },
    getStdInfo() {
      if (this.popupParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.standardInfo = _result.data;
          this.updateMode = true;
        },);
      }
    },
    getDetail() {
      if (this.popupParam.iimAccidentId) {
        this.$http.url = this.$format(this.getInfoUrl, this.popupParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data;
          this.updateMode = true;
        },);
      }
    },
    addImpr() {
      this.popupOptions.title = '개선 요청';
      this.popupOptions.param = {
        requestContents: this.standardInfo.accidentName,
        plantCd: this.standardInfo.plantCd,
        relationTableKey: this.popupParam.iimAccidentId,
        ibmTaskTypeCd: 'ITT0000015',
        row: this.standardInfo,
        ibmTitle: this.standardInfo.accidentName,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeOutPopup;
    },
    addiimImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.standardInfo.accidentName,
        plantCd: this.standardInfo.plantCd,
        relationTableKey: this.popupParam.iimAccidentId,
        ibmTaskTypeCd: 'ITT0000015',
        row: this.standardInfo,
        ibmTitle: this.standardInfo.accidentName,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediate.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeOutPopup;
    },
    closeOutPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeOutPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeOutPopup;
      }
    },
    saveAccident() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
